import { ErrorBoundary, LEVEL_INFO } from '@rollbar/react'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { initialTheme } from 'themes/constants'
import { applyTheme } from 'themes/utils'
import { DangerousElement } from 'shared/components/dangerous-element'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { AccessDeniedReasonsEnum, getAccessDeniedText } from 'shared/errors/access-denied'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { classWithModifiers } from 'shared/utils/className-with-modifiers'
import { getSchoolUrl } from 'modules/school/utils/get-school-url'
import ThemeEditor from 'modules/theme-editor/components/theme-editor'
import { useCourseData } from '../hooks/use-course-data'
import { useCourseMenu } from '../hooks/use-course-menu'
import { useGetCoursePageUrl } from '../hooks/use-course-path-url'
import { useIsUserAdminOrOwnerOfCourse } from '../hooks/use-is-user-admin-or-owner-of-course'
import { addPublicCustomClassNamePrefix } from '../utils/add-class-prefix'
import { CourseContentSkeleton } from './course-content-skeleton'
import { CourseAccessFromScreen } from './course-info-screens/course-access-from-screen'
import { CourseInfoScreenLayout } from './course-info-screens/course-info-screen-layout'
import { CourseLoginOrPurchaseScreen } from './course-info-screens/course-login-or-purchase-screen'
import { CourseLoginScreen } from './course-info-screens/course-login-screen'
import { CourseSideMenu } from './course-side-menu'
import MobileCourseSideMenu from './mobile-side-menu'

const EmptyError = () => <span />

const CourseLayout = ({ children }: React.PropsWithChildren) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const coursePath = useGetCoursePageUrl()
  const { user } = useUser()
  const isUserAdminOrOwnerOfCourse = useIsUserAdminOrOwnerOfCourse()
  const { courseData } = useCourseData()
  const { courseMenu, areAllModulesUnavailable } = useCourseMenu({
    courseId: courseData?.id,
  })
  const isAccessDenied = courseData?.isDeactivated && !isUserAdminOrOwnerOfCourse

  const router = useRouter()
  const { t } = useTranslation()

  const isLoading = !user || !courseData || !courseMenu

  const render = () => {
    if (!user || !courseData) {
      return <CourseContentSkeleton />
    }

    if (!isAuthorizedUser(user)) {
      if (courseData?.salesPage) {
        return <CourseLoginOrPurchaseScreen />
      }

      return <CourseLoginScreen />
    }

    if (courseData.accessFrom) {
      return <CourseAccessFromScreen />
    }

    return children
  }

  useLayoutEffect(() => {
    applyTheme(initialTheme)
    if (courseData?.colorSet) applyTheme(courseData.colorSet)
  }, [courseData?.colorSet])

  const isCourseUnavailable =
    isAuthorizedUser(user) &&
    courseData &&
    !isAccessDenied &&
    !courseData.accessFrom &&
    areAllModulesUnavailable &&
    !isUserAdminOrOwnerOfCourse

  useEffect(() => {
    if (isCourseUnavailable) {
      if (courseData.salesPage) {
        window.location.assign(courseData.salesPage)
      } else {
        router.push(getSchoolUrl())
      }
    }
  }, [isCourseUnavailable, courseData, router])

  return (
    <>
      <h2
        className={`text-3xl font-bold text-THEME-primary ${
          isLoading ? 'w-40 animate-pulse rounded-md bg-THEME-disabled text-transparent' : ''
        } ${classWithModifiers(addPublicCustomClassNamePrefix('course__title'), {
          ['loading']: isLoading,
        })}`}
      >
        <LinkWithoutPrefetch href={`/school/course/${coursePath}`}>
          {courseData?.name || '.'}
        </LinkWithoutPrefetch>
      </h2>

      <div className="mt-7 flex flex-col gap-3 md:gap-5 lg:flex-row lg:gap-12">
        <div
          className={`hidden shrink-0 md:block lg:mb-[-20px] ${classWithModifiers(
            addPublicCustomClassNamePrefix('course__side-menu-container'),
            {
              ['loading']: isLoading,
            },
          )}`}
        >
          <CourseSideMenu isLoading={isLoading} />
        </div>
        <div
          ref={contentRef}
          className={`flex min-w-0 basis-full ${addPublicCustomClassNamePrefix(
            'course__content-container',
          )}`}
        >
          {isAccessDenied ? (
            <CourseInfoScreenLayout
              text={
                <span className="whitespace-pre-line">
                  {t(getAccessDeniedText(AccessDeniedReasonsEnum.Deactivated))}
                </span>
              }
            />
          ) : (
            render()
          )}
        </div>
      </div>
      <ThemeEditor />
      <MobileCourseSideMenu className="fixed bottom-3 right-3 z-10 md:hidden" />
      <ErrorBoundary fallbackUI={EmptyError} level={LEVEL_INFO} extra={{ skip: true }}>
        {courseData?.embeddedCode && <DangerousElement markup={courseData.embeddedCode} />}
      </ErrorBoundary>
    </>
  )
}

export default CourseLayout
